import { getOrCreateProcedure } from "o365-modules";
import { alert } from "o365-vue-services";

const procGetCustomizations = getOrCreateProcedure({ procedureName: "astp_Trong_Custom_Fetch" });
const gAppID = window.location.pathname.split("/").pop();
await procGetCustomizations.execute({ App_ID: gAppID }).then(function(response:any) {
    var vIsProduction = true;
    document.head.querySelectorAll("script[src]").forEach((pScript:any) => {
        if (pScript.src.indexOf("/nt/scripts/site/o365.modules.shims.") >= 0) {
            vIsProduction = false;
        }
    });

    response.Table.forEach((pRow:any) => {
        if (pRow.Extension === "ts") { pRow.Extension += ".js"; }

        let vScript = document.createElement("script");
        vScript.setAttribute("type", vIsProduction ? "module" : "module-shim");
        vScript.setAttribute("src", `/nt/scripts/apps/${gAppID}/${pRow.App_ID}.${pRow.FileName}.${pRow.Updated}.${pRow.Extension}`);

        // vScript.setAttribute("type", "module");
        // vScript.setAttribute("src", `/nt/scripts/apps/${pRow.App_ID}/${pRow.App_ID}.${pRow.FileName}.${pRow.Updated}.${pRow.Extension}`);
        document.body.append(vScript);
    });
});

if (window["o365"] === undefined) { window["o365"] = {} };
const o365 = window["o365"];
o365.custom = {
    queues: {
        vNodes: { count: 0 },
        selectors: { count: 0 },
    },
    findElement: function(pOptions:any, pCallback:any) {
        if (typeof pCallback === "function") {
            let vList:string, vKey:string;

            if (typeof pOptions === "string") { //cssSelector
                vList = "selectors";
                vKey = pOptions;
            }
            else if (pOptions.attribute && pOptions.value) {
                vList = "vNodes";
                vKey = pOptions.attribute.toLowerCase() + pOptions.value.toLowerCase().replaceAll(" ", "_");
            }
            else { return; }

            this.queues[vList].count++;
            this.queues[vList][vKey] = pCallback;

            this.startObserver();
        }
    },
    // findElement: function(pAttribute:any, pValue:any, pCallback:any) {
    //     if (typeof pCallback === "function") {
    //         this.queues.count++;
    //         this.queues[pAttribute.toLowerCase() + pValue.toLowerCase()] = pCallback;
    //         this.startObserver();
    //     }
    // },
    startObserver: function() {
        if (this.startObserver.started === true) { return; }

        this.startObserver.started = true;
        var that = this;
        this.observer = new MutationObserver(function() {
            that.findVNode();
            that.findSelector();
        });

        setTimeout(function() {
            that.findVNode();
            that.findSelector();
            that.observer.observe(document.querySelector("div[data-v-app]"), { childList: true, subtree: true });
        }, 500);
    },
    stopObserver: function() {
        if (this.stopObserver.stopping === true) { return; }

        this.stopObserver.stopping = true;
        var that = this;
        setTimeout(function() {
            if (that.queues.vNodes.count === 0 && that.queues.selectors.count === 0 && that.startObserver.started === true) {
                that.observer.disconnect();
                delete that.startObserver.started;
                delete that.stopObserver.stopping;
            }
        }, 1500);
    },
    findSelector: function() {
        if (this.queues.selectors.count === 0) { return; }
        Object.keys(this.queues.selectors).forEach((pSelector:string) => {
            var vElement = document.querySelector(pSelector);
            if (vElement !== null) {
                this.queues.selectors[pSelector](vElement);
                delete this.queues.selectors[pSelector];
                this.queues.selectors.count--;
                this.stopObserver();
            }
        });
    },
    findVNode: function(pNode:any) {
        if (this.queues.vNodes.count === 0) { return; }
        if (pNode === undefined) { pNode = document.querySelector("div[data-v-app]")._vnode; }

        var vAction = null,
            vElement = null;

        if (pNode.dirs && pNode.dirs[0]?.arg?.length > 0) { //v-target
            vAction = "v-target" + pNode.dirs[0].arg.toLowerCase();
        }
        else if (pNode.el?.nodeName === "DIV" && pNode.ctx?.type?.name === "PropertiesItem" && pNode.ctx?.props?.fieldName !== undefined) { //OPropertiesItem
            vAction = "property" + pNode.ctx.props.fieldName.toLowerCase().replaceAll(" ", "_");
        }

        if (vAction !== null && this.queues.vNodes[vAction] !== undefined) {
            if (vElement === null) { vElement = pNode.el; }
            this.queues.vNodes[vAction](vElement);
            delete this.queues.vNodes[vAction];
            this.queues.vNodes.count--;
            this.stopObserver();

            if (this.queues.vNodes.count === 0) { return; }
        }

        if (pNode.children?.length > 0 && pNode.children.forEach !== undefined) {
            pNode.children.forEach((pChild:any) => {
                if (pChild.__v_isVNode === true) { this.findVNode(pChild); }
            });
        }

        if (pNode.component?.subTree?.__v_isVNode === true) {
            this.findVNode(pNode.component?.subTree);
        }
    },
    lock: function(pMessage:string, pDocument:any) {
        if (pDocument === undefined) { pDocument = document; }

        if (pDocument.getElementById("cLock_Background") !== null) {
            this.unlock();
        }
        
        if (pMessage === undefined) {
            pMessage = "Please wait...";
        }
        
        let vDiv = document.createElement("div");
        vDiv.id = "cLock_Background";
        vDiv.style.position = "fixed";
        vDiv.style.top = "0";
        vDiv.style.left = "0";
        vDiv.style.width = "100%";
        vDiv.style.height = "100%";
        vDiv.style.backgroundColor = "whitesmoke";
        vDiv.style.zIndex = "1000000000000";
        vDiv.style.opacity = "50%";
        pDocument.body.appendChild(vDiv)

        vDiv = document.createElement("div");
        vDiv.id = "cLock_Message";
        vDiv.style.position = "fixed";
        vDiv.style.margin = "auto";
        vDiv.style.top = "50%";
        vDiv.style.bottom = "50%";
        vDiv.style.width = "100%";
        vDiv.style.height = "30px";
        vDiv.style.textAlign = "center";
        vDiv.style.zIndex = "1000000000001";
        vDiv.style.fontSize = "16pt";
        vDiv.style.fontWeight = "bold";
        vDiv.style.color = "darkred";
        vDiv.innerHTML = "<span class='spinner-border'></span> " + pMessage;
        pDocument.body.appendChild(vDiv)
    },
    unlock: function(pDocument:any) {
        if (pDocument === undefined) { pDocument = document; }

        pDocument.body.removeChild(pDocument.getElementById("cLock_Message"));
        pDocument.body.removeChild(pDocument.getElementById("cLock_Background"));
    },
    toast: function(pMessage:string, pType:string, pDelay:number) {
        if (pType === undefined ) { pType = "info"; }
        if (pDelay === undefined) { pDelay = 2250; }
        alert(pMessage, pType, {autohide: true, delay: pDelay});
    },
    addJavaScript: function(app_ID:string, fileName: string) {
        if (fileName.endsWith(".js")) { fileName = fileName.substring(0, fileName.length - 3); }

        let vScript = document.createElement("script");
        vScript.setAttribute("type", "module");
        vScript.setAttribute("src", `/nt/scripts/apps/${app_ID}/${app_ID}.${fileName}.${new Date().getTime()}.js`);
        document.body.append(vScript);
    }
};

// o365.custom.findElementByAttribute = function(attribute:string, searchFor:string, cssSelector:string) {
//     if (["title"].indexOf(attribute) >= 0) {
//         cssSelector += `[${attribute}="${searchFor}"]`;
//     }

//     var vFound = null,
//         vNodeList = document.querySelectorAll(cssSelector);

//     if (vNodeList.length === 1) {
//         return vNodeList[0];
//     }
//     else if (vNodeList.length > 0) {
//         for (let i = 0; i < vNodeList.length; i++) {
//             let vNode = vNodeList[i],
//                 vObject;

//             if (vNode.__vnode) {
//                 switch (attribute) {
//                     case "v-target":
//                         vObject = vNode.__vnode.dirs;
//                         if (vObject && vObject[0].arg === searchFor) {
//                             vFound = vNode;
//                         }
//                         break;

//                     case "click":
//                         vObject = vNode.__vnode.props?.onClick;
//                         if (vObject && (vObject.name === searchFor || vObject.toString().indexOf(searchFor) >= 0)) {
//                             vFound = vNode;
//                         }
//                         break;
//                 }

//                 if (vFound !== null) { break; }
//             }
//         }
//     }

//     if (vFound === null) { console.warn("Unable to find element: " + attribute + " = " + searchFor); }
//     return vFound;
// };